/* 
  * @Author: 东隅  
  * @Date: 2020年02月25日10:46:38
  * @Last Modified by:   dongyu  
  * @Last Modified time: 2020年02月25日10:46:38  
  * @Main: 辅助函数
  */

//  const debug = require('debug')('ppPay: ');
//  const callbacks = require('./callbacks')



 const utils  = {
	// request: function(url,method,data,successCallback,errorCallback, headers) {
	// 	if(typeof XMLHttpRequest === "undefined") {
	// 		console.warn('http request is not support')
	// 		// throw new ppPayError('http request is not support')
	// 	}
	// 	const xhr = new XMLHttpRequest()
	// 	if(typeof xhr.timeout !== 'undefined') {
	// 		xhr.timeout = 120 * 1000
	// 	}
	// 	method = method.toUpperCase()

	// 	if(method === 'GET' && typeof data === 'object' && data) {
	// 		url+='?' + utils.stringifyData(data)
	// 	}
	// 	xhr.open(method, url, true)
	// 	if(typeof headers !== 'undefined') {
	// 		for(var j in headers) {
	// 			if({}.hasOwnProperty.call(headers,j)) { // 对headers过滤
	// 				xhr.setRequestHeader(j, headers[j])
	// 			}
	// 		}
	// 	}

	// 	if(method === 'POST') {
	// 		xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
	// 		try {
	// 			// const encode = crypto.encrypt(JSON.stringify(data))
	// 			// const cryptoData = {
	// 			// 	data: encode
	// 			// }
	// 			// debug('encrypt data success')
	// 			xhr.send(JSON.stringify(data));
	// 		} catch (error) {
	// 			callbacks.install({
	// 				code: 500,
	// 				description: "Request Error 请检查网络"
	// 			})
	// 		}
	// 		// const valencode = crypto.decrypt(encode)
	// 		// console.log('解密内容',valencode)
	// 	} else {
	// 		xhr.send();
	// 	}

	// 	if( typeof successCallback === 'undefined') {
	// 		successCallback = function(x,y,z) {
	// 			// console.log(x,y,z)
	// 		}
	// 	}

	// 	if (typeof errorCallback === 'undefined') {
	// 		errorCallback = function (x,y,z) {
	// 			// console.log(x,y,z)
	// 		}
	// 	}

	// 	xhr.onreadystatechange = function () {
	// 		if (xhr.status === 200) {
	// 			if (xhr.readyState == 4) { // 成功
	// 				// debug('reveice data',JSON.parse(xhr.responseText))
	// 				try {
	// 					const res = JSON.parse(xhr.responseText)
	// 					successCallback(res, xhr.status, xhr);
	// 				} catch (error) {
	// 					errorCallback(xhr, xhr.status)
	// 					callbacks.install({
	// 						code: 500,
	// 						description: 'Response Error 请求出错，请检查参数是否完整'
	// 					})
	// 				}

	// 			}
	// 		}
			
	// 	};

	// 	xhr.onerror = function (e) {
	// 		callbacks.install({
	// 			code: 500,
	// 			description: 'Request Error 请求出错，请检查网络'
	// 		})
	// 		// errorCallback(xhr, 0, e);
	// 	};
	// },
	documentReady: function(fn) {
		if (typeof document === 'undefined') {
			fn();
			return;
		}
		if (document.readyState != 'loading') {
			fn();
		} else {
			document.addEventListener('DOMContentLoaded', fn);
		}
	},
	stringifyData: function(data) {
		var output = [];
		for(var i in data) {
			output.push(i + '='+ encodeURIComponent(data[i]))
		}
		return output.join('&')
	},
	// loadJs: function(url,fn) {
	// 	var script = document.createElement('script')
	// 			// fn = callback || function(){};
	// 			script.async = true;
    // 	script.type = 'text/javascript';
	// 	//IE
	// 	if(script.readyState){
	// 		script.onreadystatechange = function(){
	// 			if( script.readyState == 'loaded' || script.readyState == 'complete' ){
	// 				script.onreadystatechange = null;
	// 				fn();
	// 			}
	// 		};

	// 	} else{
    //     //其他浏览器
	// 		script.onload = function(){
	// 			fn();
	// 		};

    // 	}
	// 	script.src = url;
	// 	document.getElementsByTagName('head')[0].appendChild(script);
	// },
	// loadStyle: function (url) { 
	// 	var link = document.createElement('link');
	// 	link.rel = "stylesheet";
	// 	link.type = "text/css";
	// 	link.href = url;
	// 	var head = document.getElementsByTagName("head")[0];
	// 	head.appendChild(link);
	// },
	createDom: function(id) {
		const formDiv = document.createElement('div')
		formDiv.id = id
		document.body.appendChild(formDiv)
		
	},
	// loadFile: function(url,fn) {
	// 	var script = document.createElement('script')
	// 	// fn = callback || function(){};
	// 	script.type = 'x-template';
	// 	script.id = "vueTpl"
	// 	if(script.readyState){
	// 		script.onreadystatechange = function(){
	// 			if( script.readyState == 'loaded' || script.readyState == 'complete' ){
	// 				script.onreadystatechange = null;
	// 				fn();
	// 			}
	// 		};

	// 	} else{
    //     //其他浏览器
	// 		script.onload = function(){
	// 			fn();
	// 		};

    // 	}
	// 	script.src = url;
	// 	document.getElementsByTagName('head')[0].appendChild(script);
	// },
	removeDocument() {
		const dom = document.getElementById('pp_pay_dom')
		setTimeout(function(){
			dom.remove()
		},1000)
		
	},
	/** 
	 * 销毁iframe，释放iframe所占用的内存。 
	 * @param iframe 需要销毁的iframe id 
	 */
	destroyIframe() {
		var iframe = document.getElementById('sdkIframe');
		//把iframe指向空白页面，这样可以释放大部分内存。 
		iframe.src = 'about:blank';

		try {
			iframe.contentWindow.document.write('');
			iframe.contentWindow.document.clear();
		} catch (e) {}

		//把iframe从页面移除 
		iframe.parentNode.removeChild(iframe);

	},
	envUrl(mode) {
		const url = {
			local: 'http://localhost:9527',
			test: 'https://test-pay-checkout.pingpongx.com',
			build: 'https://pay-checkout.pingpongx.com',
			sandbox: 'https://sandbox-pay-checkout.pingpongx.com',
			pci: "https://acquirer-pay-front.lejiapay.com",
			fra: 'https://pay-checkout.pingpongx.com'
		}
		return url[mode] || url.sandbox
	},
	// 检测是否是手机端
	uaCheckisMobile() {
		const ua = navigator.userAgent;
		if(!ua) return false
		const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
				isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
				isAndroid = ua.match(/(Android)\s+([\d.]+)/),
				isMobile = isIphone || isAndroid;
		return !!isMobile
			
	}
}


module.exports = utils