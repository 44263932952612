/* 
  * @Author: 东隅  
  * @Date: 2020年02月25日10:46:38
  * @Last Modified by:   dongyu  
  * @Last Modified time: 2020年02月25日10:46:38  
  * @Main: sdk版本管理
  */

 module.exports = {
  v: '1.2.0'
}