const callbacks = {
	paymentCallback: null,

}

callbacks.install = function (result) {
	if (typeof callbacks.paymentCallback === 'function') {
		const rep = {
			result
		}
		callbacks.paymentCallback(rep)
	}

}

module.exports = callbacks