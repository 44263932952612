/* 
 * @Author: 东隅  
 * @Date: 2020年02月25日10:46:38
 * @Last Modified by:   dongyu  
 * @Last Modified time: 2020年02月25日10:46:38  
 * @Main: 框架初始化 
 */
import utils from "./utils"
// import error from "./error"
// js完成后开始渲染表单
module.exports = function (options) {
	const env = utils.envUrl(options.mode)
	const iframe = document.createElement('iframe');
	iframe.id = "sdkIframe"
	iframe.style.width = '100%'
	iframe.style.height = '100%'
	iframe.style.border = "none"
	const t = new Date().getTime()
	iframe.src = `${env}/sdk.html?_=${t}`
	iframe.onreadystatechange = function () {
		if (iframe.readyState !== 'complete') {
			return 'doing'
		} else {
			return 'complete'
		}
	};
	// console.log('onload start')
	iframe.onload = () => {
		// console.log('onload success')
		// console.log('options params', options)
		options.sourceTarget = document.location.pathname === '/' ? document.location.origin : document.location.origin + document.location.pathname
		console.log('初始化basicConfig参数', (new Date()).toLocaleTimeString())
		// console.log('发送参数', (new Date()).toLocaleTimeString(), `${env}/sdk.html`)
		options.type = "basicConfig"
		document.getElementById('sdkIframe').contentWindow.postMessage(options, `${env}/sdk.html`);
		console.log('发送参数完成', (new Date()).toLocaleTimeString())
		iframe.readyState = 'complete'
	};

	const findId = document.querySelectorAll(options.root)[0]
	if (options.root && findId) {
		findId.style.width = (options.base && options.base.width) ? options.base.width : '500px'
		findId.style.height = (options.base && options.base.height) ? options.base.height : '800px'
		findId.appendChild(iframe);

	} else {
		const container = document.createElement("div")
		container.id = "ppPay"
		document.body.appendChild(container)
		options.root = "#ppPay"
		const el = document.querySelectorAll('#ppPay')[0]
		el.appendChild(iframe);
	}


	const opts = Object.assign({
		// 参数列表
		inited: false,
	}, options) // 合并options
	// 标记初始化完成
	opts.inited = true
	return opts
}