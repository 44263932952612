/* 
  * @Author: 东隅  
  * @Date: 2020年02月25日10:46:38
  * @use new ppPayError(name, error)
  * @Main: 统一ppPay 错误处理 
  */

const error = function ppPayError(message, extra) {
    this.message = message
    this.extra = extra
}

module.exports = {
    Error: error
}