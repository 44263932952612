/* 
 * @Author: 东隅  
 * @Date: 2020年02月25日10:46:38
 * @Last Modified by:   dongyu  
 * @Last Modified time: 2020年02月25日10:46:38  
 * @Main: 支付逻辑
 */

const version = require('./version').v
const _iniOptions = require('./initOptions')
const ppPayError = require('./error').Error
const callbacks = require('./callbacks')
const utils = require('./utils')
const Fingerprint2 = require("fingerprintjs2");


function Client(options) {
	if (!(this instanceof Client)) {
		return new Client(options)
	}
	// 检查是否已经初始化
	if (options && options.inited) {
		this.options = options;
		console.warn('请勿重复调用');
		return;
	} else {
		this.options = Client.initOptions(options); // 获取默认初始参数
	}
}
Client.initOptions = function initOptions(options) {
	// 正常流程第一次合并参数并返回
	return _iniOptions(options)
}

module.exports = Client;
/**
 * 扩展 client
 */

const proto = Client.prototype
proto.clientInvoked = false
// 创建支付
proto.createPayment = function (
	sdkConfig,
	callback
) {
	if (this.clientInvoked) {
		console.warn("请勿重复调用");
		callbacks.install({
			code: "500",
			msg: "请勿重复调用"
		})
		return;
	}
	this.clientInvoked = true
	if (typeof callback === "function") {
		callbacks.paymentCallback = callback
	}
	window.addEventListener("message", e => {
		if (e.data) {
			if (e.data.type === 'normal') {
				// console.log('123sdada', e.data)
				window.location.href = e.data.url
			} else if (e.data.type === '3ds') {
				formSubmit(
					e.data.url,
					e.data.method, {
					...e.data.params,
				},
					document.querySelectorAll(sdkConfig.root)[0] || document.body
				)
			} else if (e.data.type === '3dsv2') { 
				formSubmit3ds(
					e.data.url,
					e.data.method,
					{
						...e.data.params,
					},
					document.querySelectorAll(sdkConfig.root)[0] || document.body
				)
			}
		}
	});
	try {

		if (sdkConfig.token) {
			const options = { // 设备指纹获取
				fonts: {
					extendedJsFonts: true
				},
				excludes: {
					userAgent: true
				}
			};
			// sdkConfig.iframe = true
			Fingerprint2.getV18(options, (result, components) => {
				if (result) {
					const el = document.getElementById('sdkIframe')
					if (!el) { 
						callbacks.install({
							code: 500,
							msg: 'can not find dom iframe'
						})
						return;
					}
					sdkConfig.fingerPrint = result
					sdkConfig.type = "postData"
					console.log('token', sdkConfig)
					const env = utils.envUrl(this.options.mode)
					console.log('发送参数', (new Date()).toLocaleTimeString())
					const iframeId = document.getElementById('sdkIframe')
					// console.log('iframeId onreadystatechange', iframeId.onreadystatechange())					
					callbackSend(sdkConfig, env)
					console.log('发送参数完成', (new Date()).toLocaleTimeString())
					
				} else {
					callbacks.install({
						code: 500,
						msg: '设备指纹采集失败'
					})
				}

			});
		} else {
			console.warn(`初始化参数缺失`)
			callbacks.install({
				code: 500,
				msg: '缺少token参数'
			})
			throw new ppPayError('缺少token参数')

		}


	} catch (e) {
		if (e instanceof ppPayError) {
			callbacks.install({
				code: 500,
				msg: e.message
			})
			return;
		} else {
			throw e;
		}
	}

}

proto.actionPayment = function () {
	if (!this.options.manul) {
		console.warn("actionPayment failed, please enable manul mode")
		return;
	}
	// console.log('this.options', this.options)
	const data = {
		type: 'invoke',
		action: 'payment'
	}
	console.log('payment action')
	document.getElementById('sdkIframe').contentWindow.postMessage(data, '*');

}

function formSubmit(url, method, params, wrap) {
	const formDOM = document.createElement("form");
	formDOM.style.display = "none";
	formDOM.setAttribute("method", method);
	wrap.appendChild(formDOM);

	var htmlString = "";
	for (var key in params) {
		if (params[key]) {
			htmlString = htmlString.concat(
				'<input type="hidden" name="' +
				key +
				'" value="' +
				params[key] +
				'"/>'
			);
		}
	}
	formDOM.innerHTML = htmlString;
	formDOM.setAttribute("action", url);
	formDOM.submit();
}

function formSubmit3ds(url, method, params, wrap) {
	if (method === 'GET' || method === 'get') {
		window.location.href = url;
		return;
	}
	const formDOM = document.createElement("form");
	formDOM.style.display = "none";
	formDOM.setAttribute("method", method);
	wrap.appendChild(formDOM);

	var htmlString = "";
	for (var key in params) {
		htmlString = htmlString.concat(
			'<input type="hidden" name="' +
			key +
			'" value="' +
			params[key] +
			'"/>'
		);
	}
	formDOM.innerHTML = htmlString;
	formDOM.setAttribute("action", url);
	formDOM.submit();
}
let timeClear = null
function callbackSend(data, env) {
	const iframeId = document.getElementById('sdkIframe')
		// console.log('iframeId onreadystatechange', iframeId.onreadystatechange())
	if (iframeId.onreadystatechange() !== 'complete') {
		if (!timeClear) {
			timeClear = setInterval(() => {
				callbackSend(data, env)
			}, 50);
		}
	} else { 
		clearTimeout(timeClear)
		timeClear = null
		// postMessage('sdkIframe', )
		document.getElementById('sdkIframe').contentWindow.postMessage(data, `${env}/sdk.html`);
		return;
	}
}